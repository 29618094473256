function addBreaks(str: string) {
  return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
}

function addColors(str: string) {
  return str
    .replace(/\[white](.*)\[\/white]/g, '<span class="text-white">$1</span>')
    .replace(/\[lightblue](.*)\[\/lightblue]/g, '<span class="text-blue-light">$1</span>')
    .replace(/\[lightpurple](.*)\[\/lightpurple]/g, '<span class="text-purple-light">$1</span>')
    .replace(/\[darkpurple](.*)\[\/darkpurple]/g, '<span class="text-purple-dark">$1</span>');
}

function addAnimation(str: string) {
  const matches = str.match(/\[animate](.*)\[\/animate]/);

  if (!matches?.length) {
    return str;
  }

  const words = matches[1].split('|');

  if (!words?.length) {
    return str;
  }

  const wrappedWords: string[] = [];

  words.forEach((word: string) => {
    wrappedWords.push(`<span class="absolute opacity-0 invisible" data-gsap-target="word">${word}</span>`);
  });

  const outputWords = `<span class="relative">${wrappedWords.join('')}</span>`;

  return str.replace(/\[animate](.*)\[\/animate]/g, outputWords);
}

function addFontSerif(str: string) {
  return str.replace(/__(.*)__/g, '<span class="font-serif">$1</span>');
}

function addAlignments(str: string) {
  return str.replace(/\[right](.*)\[\/right]/g, '<div class="text-right">$1</div>');
}

function addIndentations(str: string) {
  return str.replace(/[[]([\d]+([.][\d])?|[-][\d]*)%]/g, '<span style="display: inline-block; margin-left: $1%"></span>');
}

function addTextDirection(str: string) {
  return str.replace(/\[rtl](.*)\[\/rtl]/g, '<div class="lg:rtl">$1</div>');
}

export default (str: string) => {
  let title = str;

  title = addBreaks(title);
  title = addColors(title);
  title = addAnimation(title);
  title = addFontSerif(title);
  title = addAlignments(title);
  title = addIndentations(title);
  title = addTextDirection(title);

  return title;
};
